.FooterContainer {
  background-color: rgb(47, 48, 48);
  min-height: 55px;
}

.copyrightMessage {
  margin: 0px;
  color: white;
  /* padding-left: 37vw; */
  padding-top: 19px;
  text-align: center;
}

/* @media only screen and (max-height: 815px) {
  .FooterContainer {
    min-height: 55px;
  }
} */
