.about {
  min-height: 100vh;
  background-color: #e6beae;
}

.aboutInner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 85vw;
  padding-left: 7.5vw;
  padding-top: 32vh;
  padding-bottom: 32vh;
}

.avatarBox {
  /* padding-bottom: 10vh; */
}

.avatar {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  /* padding-left: 50px; */
}

.aboutDesc {
  /* padding-right: 19%; */
  /* padding-bottom: 5%; */
  font: "Open Sans";
  color: #000000;
  size: 23;
  /* max-width: 45%; */
  font-weight: 550;
  width: 30%;
}
