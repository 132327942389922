.contactContainer {
  min-height: 100vh;
  background-color: #d9af8b;
  /* padding-bottom: 7vh; */
  /* overflow: visible; */
}

.contactTitle {
  text-decoration: underline;
  font-size: 28px;
  margin-top: 0px;
  padding-top: 15vh;
  padding-left: 7vw;
}

.body {
  display: flex;
  padding-bottom: 7vh;
  /* height: 100vh; */
}
.contactForm {
  margin-left: 7vw;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  height: 70vh;
  width: 50vw;
}
.name {
  background-color: white;
  border-radius: 5px;
  width: 25vw;
}
.email {
  background-color: white;
  margin-top: 3vh !important;
  border-radius: 5px;
  width: 25vw;
}
.subject {
  background-color: white;
  margin-top: 3vh !important;
  border-radius: 5px;
  width: 25vw;
}
.message {
  /* margin-bottom: 3vh; */
  margin-top: 3vh !important;
  background-color: white;
  border-radius: 5px;
  width: 50vw;
}
.button {
  /* padding-bottom: vh; */
  width: 5vw;
  margin-top: 3vh !important;
  /* height: 1vh; */
}

.rightHalf {
  margin-left: 5vw;
}
.icons {
  /* max-width: 45%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 2vw;
}

.linkedinWrapper {
  padding-right: 15%;
}

.githubWrapper {
  padding-right: 22%;
}

.instagram {
  width: 77px;
  height: auto;
}

.github {
  width: 55px;
  height: 55px;
  /* padding-right: 50px; */
}

.linkedin {
  width: 57.5px;
  height: 57.5px;
  /* padding-right: 35px; */
}

/* Hang */
@-webkit-keyframes hvrHang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvrHang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvrHang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvrHang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvrHang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvrHang:hover,
.hvrHang:focus,
.hvrHang:active {
  -webkit-animation-name: hvrHang-sink, hvrHang;
  animation-name: hvrHang-sink, hvrHang;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

@media only screen and (max-height: 815px) {
  .contactContainer {
    min-height: 815px;
  }
}
