.appShell {
  background-color: #b2967d !important;
  overflow: hidden;
  margin-top: -75px;
}

.logo {
  color: #000000;
  size: 36;
  font-weight: 750;
  padding-bottom: 7px;
}

.logo:hover {
  cursor: pointer;
}

.button {
  color: #000000;
  padding-left: 5%;
  text-align: left;
}

a {
  text-decoration: none;
  color: #000000;
}

a:active {
  text-decoration-color: white !important;
}
