.skills {
  background-color: #e7d8c9;
  min-height: 100vh;
  /* padding-bottom: 15vh; */
}

.skillTitle {
  text-decoration: underline;
  font-size: 28px;
  margin-top: 0px;
  padding-top: 15vh;
  padding-left: 7vw;
}

.skillIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: column;
  /* margin-top: 27vh; */
}

.skillIconFirstRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* width: 28vw; */
  padding-top: 7vh;
}

.skillIconSecondRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 13vh;
  padding-bottom: 15vh;
}

.htmlIcon {
  width: 110px;
}

.cssIcon {
  width: 105px;
}

.jsIcon {
  width: 110px;
}

.javaIcon {
  padding-left: 1%;
  width: 85px;
}

.pythonIcon {
  padding-left: 4%;
  padding-top: 2%;
  width: 141px;
}

.reactIcon {
  width: 160px;
}
