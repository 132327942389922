.experience {
  min-height: 100vh;
  background-color: #eee4e1;
  padding-bottom: 7vh;
}

.experienceTitle {
  text-decoration: underline;
  font-size: 28px;
  margin-top: 0px;
  padding-top: 15vh;
  padding-left: 7vw;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.description {
  margin: 1.5em 0 2em 0em;
  padding-bottom: 100px;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #57523e;
}

.schoolButton {
  background-color: #d94c1a;
}

.projectButton {
  background-color: #f2be24;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }

  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
    margin-left: 3.5vw;
  }
}

.hvrForward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvrForward:hover,
.hvrForward:focus,
.hvrForward:active {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
