.home {
  min-height: 100vh;
  background-color: #9e2a2b;
  /* padding-right: 35vh; */
  /* background-image: url("../../images/milky.gif"); */
  overflow: auto;
}

.home::-webkit-scrollbar {
  /* WebKit */
  display: none;
}

.homeInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  /* padding-top: 10%; */
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* padding-right: 35vw; */
  padding-top: 27vh;
}

.summary {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10vh;
}

.homeDesc {
  /* padding-right: 19%; */
  /* padding-bottom: 5%; */
  font: "Open Sans";
  color: #ffffff;
  size: 23;
  /* max-width: 45%; */
  font-weight: 550;
}

.icons {
  /* max-width: 45%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 10%;
}

.linkedinWrapper {
  padding-right: 15%;
}

.githubWrapper {
  padding-right: 22%;
}

.instagram {
  width: 77px;
  height: auto;
}

.github {
  width: 55px;
  height: 55px;
  /* padding-right: 50px; */
}

.linkedin {
  width: 57.5px;
  height: 57.5px;
  /* padding-right: 35px; */
}

.avatarBox {
  padding-bottom: 10vh;
}

.avatar {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.arrow {
  margin-top: auto;
}

.arrow:hover {
  cursor: pointer;
}

/* Hang */
@-webkit-keyframes hvrHang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvrHang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvrHang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvrHang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvrHang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvrHang:hover,
.hvrHang:focus,
.hvrHang:active {
  -webkit-animation-name: hvrHang-sink, hvrHang;
  animation-name: hvrHang-sink, hvrHang;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
